
.business{
    background: url(../assets/aerial-view-business-team\ 1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* featureCard */
.featureCard{
    box-shadow: 0px 16px 40px rgba(117, 142, 254, 0.25);
    border-radius: 5px;
}


/* OUR MISSION DIV */
.missionDiv{
    background: rgba(52, 50, 50, 0.03);
}

/* our mission comments */
.missionComments {
 background: rgba(155, 225, 80, 0.1);
 border-radius: 10px;
 color: rgba(26, 25, 25, 0.49);
}



@media screen and (max-width:640px){
    .businessText{
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0em;
        text-align: left;
    }
}

@media screen and (max-width:1380px){
    .businessText{
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0em;
        text-align: left;
    }
}

@media screen and ((min-width: 1200px) and (max-width:1380px)) {
    .businessText{
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0em;
        text-align: left;
    }
    .businessCont{
        width: 15%;
    }
}

@media screen and (min-width:1500px){
    .cardHead{
        font-size: calc(15px + 0.3vw) ;
    }
    .cardText{
        font-size:calc(16px + 0.3vw);
        line-height: calc(31.5px + 0.5vh);
    }
    .testimonials{
        font-size: calc(36px + 1vw);
        line-height: calc(54px + 1vw);
    }
    .testimonies{
        font-size: 16px;
        line-height: calc(32px + 1vh);
    } 

    .users{
        font-size: calc(24px + 0.5vw) ;
        line-height: calc(31px + 1vw);
    }
    
}
@media screen and (min-width:1800px){
    .onboard{
        font-size: calc(24px + 0.5vw);
        line-height: calc(51px + 1vw);
        
    }
    .blogCardHead{
        font-size: calc(16px + 0.3vw) ;
    }
    .blogCardText{
        font-size: calc(13px + 0.3vw);
        line-height: calc(19px + 1.5vh);
    }
    .testimonies{
        font-size: calc(16px + 0.5vw);
        line-height: calc(32px + 1vh);
    } 
    .featHead, .mission,.testimonial, .blogHead{
        font-size: calc(32px + 0.8vw);
        line-height: calc(54px + 0.8vw);
    }
    .featSubHead, .blogHead{
        font-size: calc(20px + 0.8vw);
        line-height: calc(51px + 0.8vw);
    }
    .missionText, .users, .blogSubHead{
        font-size: calc(20px + 0.8vw);
        line-height: calc(30.5px + 0.5vw);
    }
    .missionCommentsText{
        font-size: calc(15px + 0.8vw);
    }
    .testimonialName{
        font-size: calc(20px + 0.5vw);
    }
}
@media screen and (min-width:2200px){

    .businessText{
        font-size: calc(24px + 0.5vw);
        font-weight: 600;
        line-height: calc(35px + 2.5vh);
    }
    .blogCardHead{
        font-size: calc(16px + 0.5vw) ;
    }
    .blogCardText{
        font-size: calc(13px + 0.5vw);
        line-height: calc(19px + 1vh);
    }
    .onboard{
        font-size: calc(24px + 0.8vw);
    }
    .missionCommentsText{
        line-height: calc(44px + 2vh);
    }


}

@media screen and (min-width:2800px){
    .cardHead{
        font-size: calc(16px + 0.7vw) ;
      
    }
    .cardText{
        font-size:calc(15px + 0.7vw);
        line-height: 150% ;
    }
} 


@media screen and (orientation:landscape) and (max-width: 900px){
    .featureCard{
        width: 30%;
    }
    .featContainer{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .businessCont div{
        height: 100%;
    }
   
}