@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./DaxlinePro-Regular/style.css");


body,p,h1, h2, h3, h4, h5, h6, div,Link,span{
    font-family: 'Daxline Pro';
   
}

*{
    margin: 0;
    padding: 0;
}

  .vidDiv:hover > .pause {
    opacity: 0.7;
    display: flex;
  }

video{
  object-fit: fill;
}

video[poster]{
  width: 100%;
  height: 100%;
}